import gsap from 'gsap'

export function disablePageScroll() {
  document.body.classList.add('disable-scroll')
}

export function enablePageScroll() {
  document.body.classList.remove('disable-scroll')
}

export function focusElement(el) {
  el.setAttribute('tabindex', 0)
  el.focus()
}

export function anchorScroll(
  smoothScroll,
  target,
  offset = 0,
  instant = false
) {
  const currentScroll = smoothScroll.scrollTop()

  // Handle selectors
  if (isNaN(target)) {
    const targetElement = document.querySelector(target)

    if (targetElement)
      target =
        targetElement.getBoundingClientRect().top + currentScroll - offset
    else return
  }

  // Calculate the maximum page scroll value to be used incase the target is higher
  const scrollLimit = document.body.offsetHeight - window.innerHeight

  const durationFromDistance = instant
    ? 0
    : Math.max(Math.abs((target - currentScroll) / 3000), 1.5)

  gsap.to(smoothScroll, {
    scrollTop: Math.min(target, scrollLimit),
    duration: durationFromDistance,
    ease: 'power2.inOut',
  })
}

export function getInternalLinkSlug(page) {
  let slug = page.slug || page.page?.slug || ''

  // Handle home page
  if (slug === '/') slug = ''

  // Prefix slugs on some content types
  let slugPrefix = ''

  switch (page.internal?.type) {
    case 'ContentfulNewsArticle':
      slugPrefix = 'news/'
      break
    case 'ContentfulOffer':
      slugPrefix = 'offers/'
      break
    default:
      slugPrefix = ''
      break
  }

  return `/${slugPrefix}${slug}`
}

// Take an array of Contentful tag objects and add a formatted (category removed) name to each item
export function addFormattedTagName(tags, group) {
  return tags.map(tag => ({
    name: tag,
    formattedName: tag.replace(`${group} `, ''),
  }))
}

// Remove a string from an array using a clone
export function removeStringFromArray(arr, string) {
  const currentFilters = arr.slice()
  currentFilters.splice(arr.indexOf(string), 1)
  return currentFilters
}

// Remove duplicates from an array
export function removeDuplicatesFromArray(arr) {
  return [...new Set(arr)]
}

// Return a list of tags that contain a given string from an array of items
export function getTagsFromItems(items, string, substring = [0, null]) {
  const everyTag = items
    .map(item =>
      item.metadata.tags.flatMap(tag =>
        tag.name.indexOf(string) === 0
          ? [tag.name.substr(substring[0], substring[1] || tag.name.length)]
          : []
      )
    )
    .flat()

  const uniqueTags = removeDuplicatesFromArray(everyTag)
  const uniqueTagsWithFormattedName = addFormattedTagName(uniqueTags, string)

  return uniqueTagsWithFormattedName
}

// Returns a tag based off an item in the URL hash
export function getTagsFromHash(tags, hash) {
  const hashTags = hash.split(',')

  const foundTags = tags.filter(tag => {
    const formattedTag = tag.formattedName.toLowerCase().replaceAll(' ', '-')

    return hashTags.includes(formattedTag)
  })

  return foundTags
}

// Check if any of an items tags match the active filters
export function checkFilterMatch(tags, filters) {
  tags = tags.map(tag => tag.name)
  const matches = tags.filter(tag => filters.includes(tag))
  return matches.length > 0
}

// Check if any of an items tags match the active date filters
export function checkDateFilterMatch(tags, months, years) {
  // Build an array of all possible combinations of months and years
  const filters = months.flatMap(month =>
    years.map(
      year =>
        `Deployment Sailing: ${year.formattedName || year}/${
          month.formattedName || month
        }`
    )
  )
  const matches = tags.filter(tag => filters.includes(tag.name))
  return matches.length > 0
}

// Convert to lowercase string with no spaces
export function formatStringToClass(string, ignoreSpaces = true) {
  if (ignoreSpaces) {
    return string.toLowerCase().replace(/[^a-zA-Z0-9]/g, '')
  } else {
    return string
      .toLowerCase()
      .replace(/[^a-zA-Z0-9\s]/g, '')
      .replaceAll(/[\s]+/g, '-')
  }
}

// Build a string of class names from tags
export function getClassListFromTags(tags, group) {
  const groupTags = tags.flatMap(tag =>
    tag.name.indexOf(`${group}:`) === 0 ? [formatStringToClass(tag.name)] : []
  )

  return groupTags.join(' ')
}

// Set social share button links
export function getSocialShareUrl(type, title = '') {
  // Set share return url
  let url = ''

  // Get current page location
  const pageUrl = window.location.href

  // Set sharer urls
  const facebookUrl = 'https://www.facebook.com/sharer/sharer.php?u='
  const twitterUrl = 'https://twitter.com/intent/tweet?url='

  switch (type) {
    case 'facebook':
      url = facebookUrl + pageUrl

      break
    case 'twitter':
      url = twitterUrl + pageUrl

      // Add text if title is provided and parse for whitespace
      if (title) {
        const parsedTitle = title.replaceAll(' ', '%20')
        url = `${url}&text=${parsedTitle}`
      }

      break
    default:
      break
  }

  return url && window.open(url, '_blank')
}

/**
 * Scroll to list item
 *
 * @param {string} id list id
 * @param {object} smoothScroll smoothScroll store object
 * @param {string} prefix prefix string
 * @param {bool} instant smooth scroll instant scroll
 */
export function scrollListAnchor(id, smoothScroll, prefix, instant = true) {
  const fallbackID = 'list'

  anchorScroll(smoothScroll, prefix + (id ? id : fallbackID), 100, instant)

  if (instant) {
    window.history.replaceState(
      {},
      document.title,
      window.location.origin + window.location.pathname
    )
  }
}
