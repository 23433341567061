import React, { useState, useEffect, useCallback, useRef } from 'react'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Container from '@components/Container'
import { hideMobileNav, useStore } from '@Store'
import MainLogo from './images/ltya.png'
import SmallLogo from './images/small-logo.png'
import MainLogoColor from './images/ltya-color.png'
import SmallLogoColor from './images/small-logo-color.png'
import {
  HeaderContainer,
  HeaderWrapper,
  HeaderLogo,
  HeaderLogoMobile,
  HeaderMain,
  HeaderLeft,
  HeaderCenter,
  HeaderRight,
  HeaderMenu,
  HeaderMenuItem,
  HeaderMenuItemChildren,
  HeaderSubMenu,
  HeaderSubMenuItem,
  HeaderNav,
  MobileMenu,
  MobileMenuItem,
  MobileNav,
  MobileNavMain,
  MobileNavSearch,
  SkipToContent,
} from './index.style'
import Button from '@components/Button'
import IconArrowDown from '@svgs/IconArrowDown'
import IconArrowUpRight from '@svgs/IconArrowUpRight'
import { anchorScroll } from '@utils'
import PageTransitionLink from '@components/PageTransitionLink'
import MenuToggle from '@components/MenuToggle'
import { Heading1, TextBody } from '@components/TextStyles'
import SearchForm from './SearchForm'
import IconDropdown from '@svgs/IconDropdown'
import gsap from 'gsap'
import StickyCountdown2 from '@components/StickyCountdown2'

const Header = ({ location, microsite }) => {
  const [searchOpen, setSearchOpen] = useState(false)
  const [showCountdown, setShowCountdown] = useState(true)
  const [micrositesOpen, setMicrositesOpen] = useState(true)
  const [theme, setTheme] = useState({ main: 'default' })
  const header = useRef()
  const headerScroll = useRef()

  const [store, dispatch] = useStore()

  const { mobileNavIsOpen, smoothScroll } = store

  const {
    contentfulGlobals: {
      bookNowUrl,
      stickyBannerHeading,
      stickyBannerText,
      countdownTitles,
      stickyBannerLink,
    },
    allContentfulMicrosite,
  } = useStaticQuery(graphql`
    query {
      contentfulGlobals {
        bookNowUrl
        stickyBannerHeading
        stickyBannerText
        countdownTitles {
          ...TitleContentBlock
        }
        stickyBannerLink {
          ...Link
        }
      }
      allContentfulMicrosite {
        nodes {
          title
          slug
        }
      }
    }
  `)

  const openSearch = e => {
    if (e) e.preventDefault()
    setSearchOpen(true)
  }

  const openMicrosites = e => {
    if (e) e.preventDefault()
    setMicrositesOpen(true)
  }

  const toggleMicrosites = e => {
    if (e) e.preventDefault()
    setMicrositesOpen(!micrositesOpen)
  }

  const hideMicrosites = e => {
    if (e) e.preventDefault()
    setMicrositesOpen(false)
  }

  useEffect(() => {
    if (!mobileNavIsOpen) {
      setMicrositesOpen(false)
      setSearchOpen(false)
      countdownVisible()
    } else {
      hideCountdown()
    }
  }, [mobileNavIsOpen])

  /**
   * Set theme ideally for homepage and use for microsite
   * Updates location state dependant of microsite template
   */
  const setThemeStyle = useCallback(() => {
    if (location.pathname === '/') {
      setTheme({ main: 'bright' })
    } else if (microsite) {
      setTheme({ main: 'microsite' })
    } else {
      setTheme({ main: 'default' })
    }
  }, [microsite, location])

  /**
   * Set theme scroll for the homepage
   * Make sure we change the theme based on trigger end
   */
  const setThemeScroll = useCallback(() => {
    if (location.pathname === '/') {
      // Grab first top section component from gatsby wrapper
      const wrapper = document.querySelector('.tl-wrapper')
      const topSection = wrapper.children && wrapper.children[0]

      // Update theme for homepage based on top level component
      topSection &&
        ScrollTrigger.create({
          trigger: '.tl-wrapper',
          start: 'top top',
          end: topSection.offsetHeight - 100,
          markers: false,
          fastScrollEnd: true,
          invalidateOnRefresh: true,
          onEnterBack: () => setTheme({ main: 'bright' }),
          onLeave: () => setTheme({ main: 'default' }),
        })
    }

    return () => {
      const triggers = ScrollTrigger && ScrollTrigger.getAll()
      triggers && triggers.forEach(trigger => trigger.kill(true))
    }
  }, [location])

  const hideCountdown = () => {
    gsap.to(header.current, {
      y: 0,
    })
    setShowCountdown(false)
  }

  const countdownVisible = () => {
    headerScroll.current?.progress < 1 &&
      setTimeout(() => {
        setShowCountdown(true)
      }, 600)
  }

  // useEffect(() => {
  //   const wrapper = document.querySelector('.tl-wrapper')
  //   const topSection = wrapper.children && wrapper.children[0]
  //   const stickyCountdown = document.querySelector('.sticky-countdown-2')

  //   headerScroll.current = ScrollTrigger.create({
  //     trigger: '.tl-wrapper',
  //     start: 'top top',
  //     end: topSection.offsetHeight - 100,
  //     markers: false,
  //     fastScrollEnd: true,
  //     onEnterBack: () => {
  //       setShowCountdown(true)
  //       gsap.to(header.current, {
  //         y: 0,
  //       })
  //     },
  //     onLeave: () => {
  //       gsap.to(header.current, {
  //         y: () => {
  //           const offsetHeight = stickyCountdown?.offsetHeight || 0
  //           return -offsetHeight
  //         },
  //       })
  //     },
  //   })

  //   return () => {
  //     const triggers = ScrollTrigger && ScrollTrigger.getAll()
  //     triggers && triggers.forEach(trigger => trigger.kill(true))
  //   }
  // }, [])

  useEffect(() => {
    setThemeStyle()
    setThemeScroll()
  }, [location, setThemeStyle, setThemeScroll])

  return (
    <ThemeProvider theme={theme}>
      <HeaderContainer ref={header}>
        <SkipToContent id="skip-to-content" href="#smooth-wrapper">
          Skip to content
        </SkipToContent>
        {/* <StickyCountdown2
          title={stickyBannerHeading}
          weekends={countdownTitles}
          text={stickyBannerText}
          link={stickyBannerLink}
          show={showCountdown}
        /> */}
        <HeaderWrapper>
          <Container fullWidth={true}>
            <HeaderMain>
              <HeaderLeft>
                <PageTransitionLink to="/">
                  <HeaderLogo src={microsite ? MainLogo : MainLogoColor} />
                  <HeaderLogoMobile
                    src={microsite ? SmallLogo : SmallLogoColor}
                  />
                </PageTransitionLink>
                <MenuToggle />
              </HeaderLeft>

              {!microsite && (
                <HeaderCenter>
                  <HeaderNav>
                    <HeaderMenu>
                      <HeaderMenuItem>
                        <PageTransitionLink to={`/offers/`}>
                          <TextBody>Offers</TextBody>
                        </PageTransitionLink>
                      </HeaderMenuItem>
                      <HeaderMenuItem>
                        <PageTransitionLink to={`/news`}>
                          <TextBody>News</TextBody>
                        </PageTransitionLink>
                      </HeaderMenuItem>
                      <HeaderMenuItem>
                        <PageTransitionLink to={`/marketing-tools/`}>
                          <TextBody>Marketing</TextBody>
                        </PageTransitionLink>
                      </HeaderMenuItem>
                      <HeaderMenuItem>
                        <PageTransitionLink to={`/training/`}>
                          <TextBody>Training</TextBody>
                        </PageTransitionLink>
                      </HeaderMenuItem>
                      <HeaderMenuItem>
                        <PageTransitionLink
                          to={`/deployment/`}
                          onFocus={hideMicrosites}
                        >
                          <TextBody>Deployment</TextBody>
                        </PageTransitionLink>
                      </HeaderMenuItem>
                      <HeaderMenuItem>
                        <button
                          onMouseOver={openMicrosites}
                          onFocus={openMicrosites}
                          onClick={openMicrosites}
                        >
                          <TextBody>
                            Microsites <IconDropdown open={micrositesOpen} />
                          </TextBody>
                        </button>
                        <HeaderMenuItemChildren
                          show={micrositesOpen}
                          onMouseLeave={hideMicrosites}
                        >
                          <HeaderSubMenu>
                            {React.Children.toArray(
                              allContentfulMicrosite.nodes.map(microsite => (
                                <HeaderSubMenuItem>
                                  <PageTransitionLink toPage={microsite}>
                                    {microsite.title}
                                  </PageTransitionLink>
                                </HeaderSubMenuItem>
                              ))
                            )}
                          </HeaderSubMenu>
                        </HeaderMenuItemChildren>
                      </HeaderMenuItem>
                    </HeaderMenu>
                  </HeaderNav>
                </HeaderCenter>
              )}

              <HeaderRight>
                <HeaderNav>
                  <HeaderMenu>
                    {microsite ? (
                      <HeaderMenuItem>
                        <Button
                          type="button"
                          onClick={() =>
                            anchorScroll(smoothScroll, '#marketing-tools')
                          }
                          iconRight={<IconArrowDown />}
                          variant={microsite ? 'primary' : 'secondary'}
                          iconOrientation="vertical"
                        >
                          Marketing Tools
                        </Button>
                      </HeaderMenuItem>
                    ) : (
                      <HeaderMenuItem>
                        <SearchForm
                          id="desktop-search"
                          focusEvent={hideMicrosites}
                        />
                      </HeaderMenuItem>
                    )}
                    <HeaderMenuItem>
                      <Button
                        href={bookNowUrl}
                        type="externalLink"
                        iconRight={<IconArrowUpRight />}
                        variant={microsite ? 'primary' : 'secondary'}
                        iconOrientation="vertical"
                      >
                        Book Now
                      </Button>
                    </HeaderMenuItem>
                  </HeaderMenu>
                </HeaderNav>
              </HeaderRight>
            </HeaderMain>
          </Container>
        </HeaderWrapper>

        <MobileNav open={mobileNavIsOpen}>
          <MobileNavMain show={!searchOpen}>
            <MobileMenu>
              <MobileMenuItem>
                <PageTransitionLink to={`/offers/`}>
                  <Heading1>Offers</Heading1>
                </PageTransitionLink>
              </MobileMenuItem>
              <MobileMenuItem>
                <PageTransitionLink to={`/news/`}>
                  <Heading1>News</Heading1>
                </PageTransitionLink>
              </MobileMenuItem>
              <MobileMenuItem>
                <PageTransitionLink to={`/marketing-tools/`}>
                  <Heading1>Marketing</Heading1>
                </PageTransitionLink>
              </MobileMenuItem>
              <MobileMenuItem>
                <PageTransitionLink to={`/training/`}>
                  <Heading1>Training</Heading1>
                </PageTransitionLink>
              </MobileMenuItem>
              <MobileMenuItem>
                <PageTransitionLink to={`/deployment/`}>
                  <Heading1>Deployment</Heading1>
                </PageTransitionLink>
              </MobileMenuItem>
              <MobileMenuItem>
                <button onClick={openSearch}>
                  <Heading1>Search</Heading1>
                </button>
              </MobileMenuItem>
              <MobileMenuItem>
                <button onClick={toggleMicrosites}>
                  <Heading1>
                    Microsites
                    <IconDropdown open={micrositesOpen} />
                  </Heading1>
                </button>
                <HeaderMenuItemChildren show={micrositesOpen}>
                  <HeaderSubMenu>
                    {React.Children.toArray(
                      allContentfulMicrosite.nodes.map(microsite => (
                        <HeaderSubMenuItem>
                          <PageTransitionLink toPage={microsite}>
                            {microsite.title}
                          </PageTransitionLink>
                        </HeaderSubMenuItem>
                      ))
                    )}
                  </HeaderSubMenu>
                </HeaderMenuItemChildren>
              </MobileMenuItem>
            </MobileMenu>
          </MobileNavMain>
          <MobileNavSearch show={searchOpen}>
            <Container>
              <SearchForm
                id="mobile-search"
                submitEvent={() => hideMobileNav(dispatch)}
                mobile
              />
            </Container>
          </MobileNavSearch>
        </MobileNav>
      </HeaderContainer>
    </ThemeProvider>
  )
}

Header.propTypes = {
  location: PropTypes.object,
  microsite: PropTypes.object,
}

export default Header
